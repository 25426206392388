import { isEmpty } from '@core/utils';

export const requiredInteger = value => (value || value === 0 ? true : 'This field is required')

export const requiredNumber = value => (value || value === 0 ? true : 'This field is required')

export const requiredLookup = function(value) {
    if (value === undefined || value === null || value === "") return "This field is required";
    return true;
};

export const positiveIntegerValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => /^-?[0-9]+$/.test(String(val)))
  }

  return /^[0-9]+$/.test(String(value)) || 'This field must be a positive integer'
}


export const integerRange = (value, min, max) => {

  if (isEmpty(value)) {
    return true
  }

  const parsed = parseInt(value);
  if (isNaN(parsed)) {
    return "This field requires a number";
  }

  if (parsed < min) {
    return `This field must be at least ${min}`;
  }

  if (parsed > max) {
    return `This field must be less than ${max}`;
  }

  return true;
}

export const floatRange = (value, min, max) => {

  if (isEmpty(value)) {
    return true
  }

  const parsed = parseFloat(value);
  if (isNaN(parsed)) {
    return "This field requires a number";
  }

  if (parsed < min) {
    return `This field must be at least ${min}`;
  }

  if (parsed > max) {
    return `This field must be less than ${max}`;
  }

  return true;
}

export const minLengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length >= length || `This field must be at least ${length} characters`
}
